import {ApplicationRef, inject, Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {APP_SSR_COOKIES} from './http-interceptor';
import {UIStorageService} from '../../oex-ui-kit/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends UIStorageService {
  private cookies = inject(CookieService);

  @Inject(APP_SSR_COOKIES)
  @Optional()
  private ssrCookies?: string;

  @Inject(PLATFORM_ID)
  private platformId!: Object

  public set(key: string, value: any) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookies.set(key, value + '', 30, '/');
  }

  public get(key: string) {
    if (isPlatformServer(this.platformId)) {
      if (!this.ssrCookies) {
        return '';
      }
      return this.getCookie(key, this.ssrCookies) ?? '';
    } else {
      return this.cookies.get(key);
    }
  }

  public remove(key: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookies.delete(key, '/');
  }

  private getCookie(name: string, cookies: string) {
    const value = `; ${cookies}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return '';
  }
}
