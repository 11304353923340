import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {catchError, Observable, OperatorFunction, tap, throwError} from "rxjs";
import {inject, InjectionToken, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalService} from "./modal.service";
import {RedirectService} from './redirect.service';

export const APP_SSR_COOKIES = new InjectionToken<any>('APP_SSR_COOKIES');

// This is interceptor for SSR to pass http only cookies to server request from client
export const httpInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);
  const rs = inject(RedirectService);
  const modal = inject(ModalService);

  // Error processing
  const processError: OperatorFunction<HttpEvent<any>, any> = catchError((e, r) => {
    if (e.status === 503) {
      void modal.show({
        title: 'Error 503 Service Temporarily Unavailable.',
        message: 'Seems site currently in updating process.\nPlease try again in a few minutes.',
        buttons: [
          {text: 'Close', close: true, primary: true}
        ]
      });
    }
    if (e.status === 404) {
      void router.navigateByUrl('/not-found');
    }
    if (e.status === 401 || e.status === 403) {
      void router.navigateByUrl('/');
    }
    if (isPlatformBrowser(platformId)) {
      // Log only in browser
      console.error(e);
    }
    return throwError(() => e);
  });

  // Process redirects for startups with changed names
  const processRedirect = (event: HttpEvent<any>) => {
    if ((event instanceof HttpResponse) && (event.body?.redirect)) {
      rs.redirectToNewSlug(event.body?.redirect)
    }
  };

  // Cookies should be sent only on SSR side
  if (isPlatformBrowser(platformId)) {
    return next(req).pipe(processError).pipe(tap(processRedirect));
  }

  const ssrCookies = inject(APP_SSR_COOKIES) || '';
  req = req.clone({withCredentials: true, headers: new HttpHeaders({cookie: ssrCookies})});
/*  req = req.clone({
    withCredentials: true,
    setHeaders: {
      cookie: ssrCookies,
      credentials: 'include' // Ensure credentials are included
    }
  });*/

  return next(req).pipe(processError).pipe(tap(processRedirect));
}


