import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy, OnInit, PLATFORM_ID, Renderer2, output, HostAttributeToken, inject } from "@angular/core";
import {isPlatformServer} from "@angular/common";

@Directive({
  standalone: true,
  selector: '[ui-scroll-visibility]'
})
export class ScrollVisibilityDirective implements AfterViewInit, OnInit, OnDestroy {
  private el = inject(ElementRef);
  private r2 = inject(Renderer2);
  private platformId = inject<Object>(PLATFORM_ID);
  private zone = inject(NgZone);

  readonly onHide = output<void>();
  private offset = 0;
  private scrollElement?: Element;
  private visible = false;
  private checkTimeout?: any;
  private checkHandler = () => this.checkVisibility();

  constructor() {
    const offset = inject(new HostAttributeToken('ui-scroll-visibility'));

    this.offset = parseInt(offset, 10);


  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.scrollElement = document.getElementsByClassName('ui-portal-page')[0];
    this.bindScrollEvent();
  }

  ngAfterViewInit() {
    if (!this.el) {
      return;
    }
    this.r2.setStyle(this.el.nativeElement, 'display', 'none');
  }

  private checkVisibility() {
    if (!this.scrollElement) {
      return;
    }

    clearTimeout(this.checkTimeout);
    this.checkTimeout = setTimeout(() => {
      if (!this.scrollElement) {
        return;
      }
      let newState = false;
      if (this.scrollElement.scrollTop > this.offset) {
        newState = true;
      } else {
        newState = false;
      }
      if (this.visible !== newState) {
        this.visible = newState;
        this.updateVisibility();

        if (!this.visible) {
          this.onHide.emit();
        }
      }
    }, 10);
  }

  updateVisibility() {
    const el = this.el.nativeElement;
    if (this.visible) {
      this.r2.removeStyle(el, 'display');
      this.r2.removeClass(el, 'ui-anim-fade-out');
      this.r2.addClass(el, 'ui-anim-fade-in');
    } else {
      this.r2.removeClass(el, 'ui-anim-fade-in');
      // this.r2.setStyle(el, 'display', 'none');
      this.r2.addClass(el, 'ui-anim-fade-out');
      this.onHide.emit();
    }
  }

  private bindScrollEvent() {
    this.zone.runOutsideAngular(() => {
      if (this.scrollElement) {
        this.scrollElement.addEventListener('scroll', this.checkHandler);
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.checkTimeout);
    this.scrollElement?.removeEventListener('scroll', this.checkHandler);
  }
}
